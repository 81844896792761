import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, lastValueFrom, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { AuthService, classToPlain, DateTimeOffset, EnterpriseDataDto, MessageButton, MessageCodes, MessageResourceManager, ModalService, OnlineService, plainToClass, ServiceResponse, ToastMessageService, ToastMessageType } from '@nts/std';
import { RouteStateService } from 'src/app/shared/services/route-state.service';
import { ReceiptLongOpApiClient } from '../api-clients/receipt-long-op.api-client';
import { ReceiptLongOpOrchestratorViewModel } from '../view-models/receipt-long-op.orchestrator-view-model';
import { ReceiptLongOp } from '../domain-models/receipt-long-op';
import { ReceiptParams } from '../domain-models/receipt-params';
import { OfflineReceipt } from '../../shared/models/offline-receipt.interface';
import { LoadingStatusInterface } from 'src/app/shared/models/loading-status.interface';
import { EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_NAME, RECEIPT_LIST_FULL_PATH, RECEIPT_LONG_OP_FULL_PATH } from 'src/app/shared/shared.module';
import { ExpenseData } from '../domain-models/expense-data';
import { ExpenseType } from 'src/app/expense-type/domain-models/expense-type';
import { LocalstorageHelper, LogService } from '@nts/std/src/lib/utility';
import { StateMachine } from 'src/app/shared/services/state-machine';
import { ReceiptParamsViewModel } from '../view-models/receipt-params.view-model';
import { StepsServiceInterface } from 'src/app/shared/models/steps-service.interface';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { AccommodationData } from '../domain-models/accommodation-data';
import { PaymentData } from '../domain-models/payment-data';
import { FoodData } from '../domain-models/food-data';
import { TransportData } from '../domain-models/transport-data';
import { MileageRefoundData } from '../domain-models/mileage-refound-data';
import { OtherData } from '../domain-models/other-data';
import { ExpenseModelData } from '../domain-models/expense-model-data';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { ExpenseSelection } from '../domain-models/expense-selection';
import { ExpenseModelDataState } from './states/expense-model-data-state';
import { ExpenseDataState } from './states/expense-data-state';
import { AccommodationDataState } from './states/accommodation-data-state';
import { FoodDataState } from './states/food-data-state';
import { MileageRefoundDataState } from './states/mileage-refound-data-state';
import { OtherDataState } from './states/other-data-state';
import { TransportDataState } from './states/transport-data-state';
import { PaymentDataState } from './states/payment-data-state';
import { StepStateInterface } from './states/step-state.interface';
import { ExpenseSelectionState } from './states/expense-selection-state';
import { Supplier } from 'src/app/external-remote/supplier/supplier';
import { ExpenseAnnotationIdentity } from 'src/app/expense-annotation/domain-models/expense-annotation.identity';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { ExpenseIdentity } from 'src/app/expense-annotation/domain-models/expense.identity';
import { ExpenseFileCollection } from '../domain-models/expense-file.collection';
import { ExpenseFileCollectionViewModel } from '../view-models/expense-file.collection-view-model';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExtendedAvailablePayment } from '../domain-models/extended-available-payment';
import { Commission } from 'src/app/external-remote/commission/commission';
import { Customer } from 'src/app/external-remote/customer/customer';
import { Lead } from 'src/app/external-remote/lead/lead';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { ExpenseFile } from '../domain-models/expense-file';
import { ExtendedAvailableExpense } from '../domain-models/extended-available-expense';
import { DomSanitizer } from '@angular/platform-browser';
import { CompilationInstructions } from 'src/app/expense-model/domain-models/compilation-instructions';
import { PersonalCompilationInstructions } from 'src/app/user-available-expenses/domain-models/personal-compilation-instructions';
import { UserOfTenantExtended } from 'src/app/external-remote/user-of-tenant/user-of-tenant-extended';

export const RECEIPT_STEPS = {
  expenseModel: 'expense-model',
  expenseClassification: 'expense-classification',
  expenseData: 'expense-data',
  foodData: 'food-data',
  accommodationData: 'accommodation-data',
  transportData: 'transport-data',
  mileageRefoundData: 'mileage-refound-data',
  otherData: 'other-data',
  paymentData: 'payment-data',
}

export const RECEIPT_VALID_ACTIONS = [
  'new',
  'edit',
  'add'
];

@Injectable()
export class ReceiptStepsService implements StepsServiceInterface {

  private CROSS_SITE_TRACKING_KEY = 'crossSiteTracking';

  currentStepChanged = new BehaviorSubject<void>(null);
  stepLoading = new BehaviorSubject<boolean>(true);
  initialized = false;
  buttonLoading = new BehaviorSubject<LoadingStatusInterface>({
    loading: false,
    iconName: '',
    skipLoader: false
  });
  validateStepChanged = new Subject<void>();
  orchestratorViewModel: ReceiptLongOpOrchestratorViewModel;
  expenseAnnotationId: number;
  remoteExpense: boolean;


  workflowNew = {
    [RECEIPT_STEPS.expenseModel]: new ExpenseModelDataState(),
    [RECEIPT_STEPS.expenseClassification]: new ExpenseSelectionState(),
    [RECEIPT_STEPS.expenseData]: new ExpenseDataState(),
    [RECEIPT_STEPS.accommodationData]: new AccommodationDataState(),
    [RECEIPT_STEPS.foodData]: new FoodDataState(),
    [RECEIPT_STEPS.mileageRefoundData]: new MileageRefoundDataState(),
    [RECEIPT_STEPS.otherData]: new OtherDataState(),
    [RECEIPT_STEPS.transportData]: new TransportDataState(),
    [RECEIPT_STEPS.paymentData]: new PaymentDataState(),
  }

  constructor(
    private apiClient: ReceiptLongOpApiClient,
    private router: Router,
    private modalService: ModalService,
    private env: EnvironmentConfiguration,
    private location: Location,
    private authService: AuthService,
    private toastMessageService: ToastMessageService,
    private onlineService: OnlineService,
    private routeStateService: RouteStateService,
    private deviceService: DeviceDetectorService,
    private telemetryService: TelemetryService,
    private domSanitizer: DomSanitizer
  ) {
    // this.updateCurrentStep();
  }

  async init(currentId: string = null, remoteExpense = false, expenseAnnotationId = null): Promise<boolean> {

    try {
      this.stepLoading.next(true);
      if (!this.initialized) {

        this.expenseAnnotationId = expenseAnnotationId;
        this.remoteExpense = remoteExpense;

        this.orchestratorViewModel = new ReceiptLongOpOrchestratorViewModel(
          this.apiClient,
          this.modalService,
          this.env,
          this.authService,
          this.toastMessageService,
          this.onlineService,
          this.domSanitizer
        );

        // il terzo output è il companyId devo filtrarli per companyId

        let syncedCommissionsData: {
          lastSync: number,
          elements: any[]
        } = await LocalstorageHelper.getStorageItem('CommissionMS.CommissionObjects.Models.Commission') as {
          lastSync: number,
          elements: any[]
        };

        if (!syncedCommissionsData || syncedCommissionsData.elements == null) {
          syncedCommissionsData = {
            lastSync: 0,
            elements: []
          }
        }

        // Filtra per companyId corrente i dati sincronizzati
        await this.filterForCompanyId(syncedCommissionsData);

        this.orchestratorViewModel.syncedCommissions = syncedCommissionsData?.elements.map((c) => {
          const commission = new Commission();
          commission.id = c[0];
          commission.description = c[1];
          return commission;
        }) || [];

        let syncedCustomersData: {
          lastSync: number,
          elements: any[]
        } = await LocalstorageHelper.getStorageItem('Subject.CustomerObjects.Models.Customer') as {
          lastSync: number,
          elements: any[]
        };

        if (!syncedCustomersData || syncedCustomersData.elements == null) {
          syncedCustomersData = {
            lastSync: 0,
            elements: []
          }
        }

        // Filtra per companyId corrente i dati sincronizzati
        await this.filterForCompanyId(syncedCustomersData);

        this.orchestratorViewModel.syncedCustomers = syncedCustomersData?.elements.map((c) => {
          const customer = new Customer();
          customer.id = c[0];
          customer.companyName = c[1];
          return customer;
        }) || [];

        let syncedLeadsData: {
          lastSync: number,
          elements: any[]
        } = await LocalstorageHelper.getStorageItem('Subject.LeadObjects.Models.Lead') as {
          lastSync: number,
          elements: any[]
        };

        if (!syncedLeadsData || syncedLeadsData.elements == null) {
          syncedLeadsData = {
            lastSync: 0,
            elements: []
          };
        }

        // Filtra per companyId corrente i dati sincronizzati
        await this.filterForCompanyId(syncedLeadsData);

        this.orchestratorViewModel.syncedLeads = syncedLeadsData?.elements.map((c) => {
          const lead = new Lead();
          lead.id = c[0];
          lead.companyName = c[1];
          return lead;
        }) || [];

        const metaDataResponse = await this.orchestratorViewModel.initMetaData();

        this.orchestratorViewModel.toastMessageService.showToastsFromResponse(metaDataResponse);

        // Inizializza l'orchestrator
        await this.orchestratorViewModel.initialize();

        if (currentId?.length > 0 && remoteExpense === false) {
          // modifica spesa locale

          let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

          if (!receipts || !Array.isArray(receipts)) {
            receipts = [];
          }

          let filteredReceipts: OfflineReceipt[] = [];

          if (receipts?.length > 0 && currentId === 'selected-receipts') {
            filteredReceipts = receipts.filter((r) => r.isSelected === true);
          } else {
            filteredReceipts = receipts.filter((r) => r.id === parseInt(currentId));
          }

          if (filteredReceipts?.length > 0) {
            const model = await this.parseFromOfflineReceipt(filteredReceipts[0])
            model.id = parseInt(filteredReceipts[0].id, 10);
            this.orchestratorViewModel.currentExpenseId = model.id;


            await this.orchestratorViewModel.getByObject<ReceiptLongOp>(model);

            const domainModel = this.orchestratorViewModel.rootViewModel.params.getDomainModel();
            domainModel.expenseAnnotationIdentity = new ExpenseAnnotationIdentity();
            domainModel.expenseAnnotationIdentity.id = expenseAnnotationId;

            await this.addFilesObjectToExpenseFileCollection(
              this.orchestratorViewModel.rootViewModel.params.expenseData.files,
              filteredReceipts[0].expenseFiles
            )

            // precarica le immagini in modo da poterle visualizzare senza doverle scaricare nuovamente
            this.orchestratorViewModel.preloadImages(this.orchestratorViewModel.rootViewModel.params.expenseData.files);

            if (expenseAnnotationId > 0) {
              // Se ho un expense annotation e ho parsato un offline receipt
              // devo uploadare i file locali in remoto e aggiornare i storage file name
              await this.beginUploadOfLocalExpenseFiles(
                this.orchestratorViewModel.rootViewModel.params.expenseData.files,
              )
            }
          }

        } else if (currentId?.length > 0 && remoteExpense === true) {
          // Modica spesa da nota spesa
          const expenseIdentity = new ExpenseIdentity();
          expenseIdentity.expenseId = parseInt(currentId, 10);
          expenseIdentity.id = expenseAnnotationId;
          this.orchestratorViewModel.currentExpenseId = expenseIdentity.expenseId;
          this.orchestratorViewModel.currentExpenseAnnotationId = expenseAnnotationId;
          const response = await this.orchestratorViewModel.getExpenseToEdit(expenseIdentity);
          if (response.operationSuccedeed === false) {
            return false;
          }

          // precarica le immagini in modo da poterle visualizzare senza doverle scaricare nuovamente
          this.orchestratorViewModel.preloadImages(this.orchestratorViewModel.rootViewModel.params.expenseData.files);
        } else if (expenseAnnotationId > 0) {
          // aggiunta remota
          const identity = new ExpenseAnnotationIdentity();
          identity.id = expenseAnnotationId;
          const response = await this.orchestratorViewModel.createExpenseByExpenseAnnotation(identity);
          this.orchestratorViewModel.toastMessageService.showToastsFromResponse(response);
        } else {
          // aggiunta spesa locale

          // in fase di creazione di una spesa locale verifico se ho i requisiti (crossSiteTracking)
          const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();

          const crossSiteStatus = await LocalstorageHelper.getStorageItem(`${this.CROSS_SITE_TRACKING_KEY}`, null, true, false);

          if (crossSiteStatus !== true) {
            if (deviceInfo.os === 'iOS' && deviceInfo.browser === 'Safari') {
              await this.modalService.showMessageAsync(
                MessageResourceManager.Current.getMessage(MessageCodes.Warning),
                MessageResourceManager.Current.getMessage('BlockCrossSiteTrackingSafariMessage'), MessageButton.Ok
              )
            }

            await LocalstorageHelper.setStorageItem(`${this.CROSS_SITE_TRACKING_KEY}`, true, null, true, false);
            window.location.reload();
          }

          // invece di utilizzare la create classica uso una versione personalizzata per le spese locali
          await this.orchestratorViewModel.createLocalExpense();
        }

        this.populateViewModelsSteps();

        this.initialized = true;

        if (this.orchestratorViewModel.rootViewModel) {
          const res = await this.orchestratorViewModel.getClassificationLabels();
          this.orchestratorViewModel.rootViewModel.params.newClassificationLabels$.next(res);
          if (this.orchestratorViewModel.rootViewModel.params.newClassificationLabels$.value && this.orchestratorViewModel.rootViewModel.params.newClassificationLabels$.value != null && this.orchestratorViewModel.rootViewModel.params.newClassificationLabels$.value.length != 0) {
            const newLabels = [];
            this.orchestratorViewModel.rootViewModel.params.newClassificationLabels$.value.forEach(e => {
              newLabels[e.classification] = e.labelDefinition;
            })
            this.orchestratorViewModel.rootViewModel.params.newLabels$.next(newLabels);
          }
        }

        return true;

      }
      this.stepLoading.next(false);
      return true;
    } catch (err) {
      LogService.warn(err);
      this.toastMessageService.showToast({
        message: MessageResourceManager.Current.getMessage('ErrorOpeningExpenseMessage'),
        type: ToastMessageType.error,
        title: MessageResourceManager.Current.getMessage(MessageCodes.Error)
      });
      return false;
    }
  }

  async beginUploadOfLocalExpenseFiles(
    expenseFileCollectionViewModel: ExpenseFileCollectionViewModel
  ) {
    for (const expenseFile of expenseFileCollectionViewModel) {

      const response = await lastValueFrom(this.orchestratorViewModel.uploadExpenseFile(expenseFile, expenseFile.fileObject));
      this.orchestratorViewModel.toastMessageService.showToastsFromResponse(response);
      if (response.operationSuccedeed === false) {
        expenseFile.fileErrors = response.errors;
        this.orchestratorViewModel.errorDuringAdd = true;
      } else {
        expenseFile.storageFileName.value = response.result.storageFileName;
      }
    }
  }

  async addFilesObjectToExpenseFileCollection(
    expenseFileCollectionViewModel: ExpenseFileCollectionViewModel,
    expenseFiles: {
      expenseFile: Object,
      fileObject: String | ArrayBuffer
    }[]
  ) {

    if (expenseFileCollectionViewModel?.length !== expenseFiles.length) {
      LogService.warn('ATTENZIONE la lista delle immagini locali è differente dalla lista delle immagini del view model', expenseFileCollectionViewModel, expenseFiles)
      return
    }

    for (const [index, expenseFile] of expenseFiles.entries()) {

      const parsedExpenseFile = plainToClass<ExpenseFile, Object>(
        ExpenseFile, expenseFile.expenseFile as string as Object)
      expenseFileCollectionViewModel[index].fileObject = new File(
        [await this.bufferToBlob(expenseFile.fileObject)],
        parsedExpenseFile.originalFileName);
      expenseFileCollectionViewModel[index].fileSize.value = expenseFileCollectionViewModel[index].fileObject.size;
    }
  }

  async onSubmit(currentId: number = 0): Promise<void> {

    const queryParams = await firstValueFrom(
      this.routeStateService.queryParams.pipe(filter((p) => p != null))
    );
    const remoteExpense = queryParams['remoteExpense'] === 'true';
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const rootViewModel = this.orchestratorViewModel.rootViewModel;

    if (expenseAnnotationId > 0) {
      this.stepLoading.next(true);
      // rootViewModel.params.expenseAnnotationId.setValue(expenseAnnotationId);

      let response: ServiceResponse;

      if (remoteExpense) {

        if (this.orchestratorViewModel.rootViewModel.params.expenseState.value === ExpenseState.IntegrateDocumentation) {
          // Integrazione spesa remota
          response = await this.orchestratorViewModel.integrateExpense(rootViewModel.getDomainModel());
        } else {
          // Aggiornamento spesa remota
          response = await this.orchestratorViewModel.updateExpense(rootViewModel.getDomainModel());
        }

      } else {
        // Aggiunta spesa locale in nota spesa remota
        response = await this.orchestratorViewModel.executeLongOp();
      }

      this.orchestratorViewModel.toastMessageService.showToastsFromResponse(response);
      if (response?.operationSuccedeed) {
        this.resetSteps();
        this.resetViewModel();

        let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

        if (!receipts || !Array.isArray(receipts)) {
          receipts = [];
        }

        if (receipts?.length > 0) {
          const receiptIndex = receipts.findIndex((r) => r.id === this.orchestratorViewModel.currentExpenseId);
          if (receiptIndex > -1) {
            // Rimuovo la receipt locale
            receipts.splice(receiptIndex, 1);
          }
          await LocalstorageHelper.setStorageItem('receipts', receipts, undefined, true, true, true);
        }

        // Verifico se ci sono altre spese locali da aggiungere
        if (receipts.findIndex((r) => r.isSelected === true) > -1) {
          const newQueryParams = {
            ...queryParams,
            expenseAnnotationId: expenseAnnotationId,
          };
          this.telemetryService.trackEvent({ name: 'WEA_ReceiptList_AddReceipt' });
          this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/add/selected-receipts`], { queryParams: newQueryParams });
        } else { // Se non ci sono spese altre spese locali da aggiungere
          const expenseAnnotationIdentity = new ExpenseAnnotationIdentity();
          expenseAnnotationIdentity.id = expenseAnnotationId;
          const rootModelFullName = EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_NAME;
          this.orchestratorViewModel.startLongOpClient<ExpenseAnnotationIdentity>(rootModelFullName, expenseAnnotationIdentity, null, true, false);
        }
      } else {
        // TODO nel caso in cui ho errori continuo resetto l'aggiunta per le altre spese?
        this.orchestratorViewModel.errorDuringAdd = true;
        this.stepLoading.next(false);
      }
    } else {

      let peopleQuantity = 0;
      let nightQuantity = 0;
      let guestQuantity = 0;
      let distance = 0;
      let unitPrice = 0;

      switch (rootViewModel.params.expenseSelection.expenseClassification.value) {
        case ExpenseClassification.Accommodation:
          peopleQuantity = rootViewModel.params.accommodationData.peopleQuantity.value;
          nightQuantity = rootViewModel.params.accommodationData.nightQuantity.value;
          break;

        case ExpenseClassification.Food:
          peopleQuantity = rootViewModel.params.foodData.peopleQuantity.value;
          guestQuantity = rootViewModel.params.foodData.guestQuantity.value;
          break;

        case ExpenseClassification.Transport:
          peopleQuantity = rootViewModel.params.transportData.peopleQuantity.value;
          break;

        case ExpenseClassification.MileageRefound:
          distance = rootViewModel.params.mileageRefoundData.distance.value;
          unitPrice = rootViewModel.params.mileageRefoundData.unitPrice.value;
          break;

        case ExpenseClassification.Other:
          peopleQuantity = rootViewModel.params.otherData.peopleQuantity.value;
          guestQuantity = rootViewModel.params.otherData.guestQuantity.value;
          break;
      }

      if (currentId > 0) {

        let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

        if (!receipts || !Array.isArray(receipts)) {
          receipts = [];
        }

        const expenseFiles = [];
        for (const singleFile of rootViewModel.params.expenseData.files) {
          expenseFiles.push({
            expenseFile: classToPlain(singleFile.getDomainModel(), { strategy: 'excludeAll' }),
            fileObject: await this.blobToBuffer(singleFile.fileObject),
          })
        }

        const tenantId: number = await this.authService.getTenantId();
        const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

        const dmExtendedExpenseType = rootViewModel.params.expenseData.extendedExpenseTypeRef.getDomainModel();
        if (dmExtendedExpenseType.availableExpenseInstructions == null) {
          dmExtendedExpenseType.availableExpenseInstructions = new CompilationInstructions();
        } else if (dmExtendedExpenseType.personalExpenseInstructions == null) {
          dmExtendedExpenseType.personalExpenseInstructions = new PersonalCompilationInstructions();
        }

        const expenseTypeRef = classToPlain(dmExtendedExpenseType, { strategy: 'excludeAll' });

        const data = {
          id: currentId,
          userId: rootViewModel.params.userId.value,
          expenseModelRef: classToPlain(rootViewModel.params.expenseModelData.expenseModelRef.getDomainModel(), { strategy: 'excludeAll' }),
          expenseType: rootViewModel.params.expenseSelection.expenseClassification.value,
          expenseTotalAmount: rootViewModel.params.expenseData.expenseTotalAmount.value,
          expenseDate: DateTimeOffset.formatToString(rootViewModel.params.expenseData.expenseDate.value),
          note: rootViewModel.params.expenseData.note.value,
          expenseTypeRef,
          commissionRef: classToPlain(rootViewModel.params.expenseData.commissionRef.getDomainModel(), { strategy: 'excludeAll' }),
          leadRef: classToPlain(rootViewModel.params.expenseData.leadRef.getDomainModel(), { strategy: 'excludeAll' }),
          customerRef: classToPlain(rootViewModel.params.expenseData.customerRef.getDomainModel(), { strategy: 'excludeAll' }),
          description: rootViewModel.params.expenseData.description.value,
          expenseFiles,
          businessName: rootViewModel.params.paymentData.businessName.value,
          peopleQuantity,
          nightQuantity,
          guestQuantity,
          distance,
          unitPrice,
          paymentTypeRef: classToPlain(rootViewModel.params.paymentData.extendedPaymentTypeRef.getDomainModel(), { strategy: 'excludeAll' }),
          reliableSupplier: rootViewModel.params.paymentData.reliableSupplier.value,
          supplierRef: rootViewModel.params.paymentData.reliableSupplier.value === true ?
            classToPlain(rootViewModel.params.paymentData.supplierRef.getDomainModel(), { strategy: 'excludeAll' }) :
            null,
          vatNumber: rootViewModel.params.paymentData.vatNumber.value,
          companyId: enterpriseData.companyId
        }
        const foundIndex = receipts.findIndex((r) => r.id === currentId);
        if (foundIndex === - 1) {
          receipts.push(data)
        } else {
          receipts[foundIndex] = data;
        }

        await LocalstorageHelper.setStorageItem('receipts', receipts, null, true, true, true);

        this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/update-complete/${rootViewModel.id.value}`], { queryParams });

      } else {

        let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

        if (!receipts || !Array.isArray(receipts)) {
          receipts = [];
        }

        let lastId = 1;
        if (receipts?.length > 0) {
          lastId = receipts[receipts?.length - 1].id + 1;
        }

        const expenseFiles: {
          expenseFile: Object,
          fileObject: ArrayBuffer
        }[] = [];

        for (const singleFile of rootViewModel.params.expenseData.files) {
          expenseFiles.push({
            expenseFile: classToPlain(singleFile.getDomainModel(), { strategy: 'excludeAll' }) as Object,
            fileObject: await this.blobToBuffer(singleFile.fileObject),
          })
        }

        const tenantId: number = await this.authService.getTenantId();
        const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

        const dmExtendedExpenseType = rootViewModel.params.expenseData.extendedExpenseTypeRef.getDomainModel();
        if (dmExtendedExpenseType.availableExpenseInstructions == null) {
          dmExtendedExpenseType.availableExpenseInstructions = new CompilationInstructions();
        } else if (dmExtendedExpenseType.personalExpenseInstructions == null) {
          dmExtendedExpenseType.personalExpenseInstructions = new PersonalCompilationInstructions();
        }

        const expenseTypeRef = classToPlain(dmExtendedExpenseType, { strategy: 'excludeAll' });

        receipts.push({
          id: lastId,
          userId: rootViewModel.params.userId.value,
          expenseModelRef: classToPlain(rootViewModel.params.expenseModelData.expenseModelRef.getDomainModel(), { strategy: 'excludeAll' }) as Object,
          expenseType: rootViewModel.params.expenseSelection.expenseClassification.value,
          expenseTotalAmount: rootViewModel.params.expenseData.expenseTotalAmount.value,
          expenseDate: DateTimeOffset.formatToString(rootViewModel.params.expenseData.expenseDate.value),
          note: rootViewModel.params.expenseData.note.value,
          description: rootViewModel.params.expenseData.description.value,
          expenseTypeRef,
          commissionRef: classToPlain(rootViewModel.params.expenseData.commissionRef.getDomainModel(), { strategy: 'excludeAll' }),
          leadRef: classToPlain(rootViewModel.params.expenseData.leadRef.getDomainModel(), { strategy: 'excludeAll' }),
          customerRef: classToPlain(rootViewModel.params.expenseData.customerRef.getDomainModel(), { strategy: 'excludeAll' }),
          expenseFiles,
          businessName: rootViewModel.params.paymentData.businessName.value,
          peopleQuantity,
          nightQuantity,
          guestQuantity,
          distance,
          unitPrice,
          paymentTypeRef: classToPlain(rootViewModel.params.paymentData.extendedPaymentTypeRef.getDomainModel(), { strategy: 'excludeAll' }),
          reliableSupplier: rootViewModel.params.paymentData.reliableSupplier.value,
          supplierRef: rootViewModel.params.paymentData.reliableSupplier.value === true ?
            classToPlain(rootViewModel.params.paymentData.supplierRef.getDomainModel(), { strategy: 'excludeAll' }) :
            null,
          vatNumber: rootViewModel.params.paymentData.vatNumber.value,
          companyId: enterpriseData.companyId
        });

        await LocalstorageHelper.setStorageItem('receipts', receipts, undefined, true, true, true);
        this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/creation-complete`]);
      }
      this.resetSteps();
      this.resetViewModel();
    }
  }

  private async filterForCompanyId(syncedData: {
    lastSync: number,
    elements: any[]
  }) {
    if (this.env.isEnterpriseBarrierRequired && syncedData?.elements?.length > 0) {
      const tenantId: number = await this.authService.getTenantId();
      const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
      syncedData.elements = syncedData.elements.filter(
        (element: any[]) => {
          // se esiste l'output per il companyId
          if (element[2]) {
            return element[2] === enterpriseData.companyId;
          } else {
            return true;
          }
        }
      )
    }
  }

  private BASE_CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  // Converts a buffer to a string to store, serialized, in the backend
  // storage library.
  bufferToString(buffer) {
    // base64-arraybuffer
    var bytes = new Uint8Array(buffer);
    var base64String = '';
    var i;

    for (i = 0; i < bytes.length; i += 3) {
      /*jslint bitwise: true */
      base64String += this.BASE_CHARS[bytes[i] >> 2];
      base64String += this.BASE_CHARS[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
      base64String +=
        this.BASE_CHARS[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
      base64String += this.BASE_CHARS[bytes[i + 2] & 63];
    }

    if (bytes.length % 3 === 2) {
      base64String = base64String.substring(0, base64String.length - 1) + '=';
    } else if (bytes.length % 3 === 1) {
      base64String =
        base64String.substring(0, base64String.length - 2) + '==';
    }

    return base64String;
  }

  stringToBuffer(serializedString: string) {
    // Fill the string into a ArrayBuffer.
    var bufferLength = serializedString.length * 0.75;
    var len = serializedString.length;
    var i;
    var p = 0;
    var encoded1, encoded2, encoded3, encoded4;

    if (serializedString[serializedString.length - 1] === '=') {
      bufferLength--;
      if (serializedString[serializedString.length - 2] === '=') {
        bufferLength--;
      }
    }

    var buffer = new ArrayBuffer(bufferLength);
    var bytes = new Uint8Array(buffer);

    for (i = 0; i < len; i += 4) {
      encoded1 = this.BASE_CHARS.indexOf(serializedString[i]);
      encoded2 = this.BASE_CHARS.indexOf(serializedString[i + 1]);
      encoded3 = this.BASE_CHARS.indexOf(serializedString[i + 2]);
      encoded4 = this.BASE_CHARS.indexOf(serializedString[i + 3]);

      /*jslint bitwise: true */
      bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
      bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }
    return buffer;
  }

  private BLOB_TYPE_PREFIX = '~~local_forage_type~';

  async blobToBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.readAsArrayBuffer(blob);
    });
  }

  async blobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onload = () => resolve(
        this.BLOB_TYPE_PREFIX +
        '[object Blob]' +
        '~' +
        this.bufferToString(
          reader.result
        )
      );
      reader.readAsArrayBuffer(blob);
    });
  }

  private BLOB_TYPE_PREFIX_REGEX = /^~~local_forage_type~([^~]+)~/;

  async base64ToBlob(value: any): Promise<Blob> {
    var serializedString = value; //.substring(this.TYPE_SERIALIZED_MARKER_LENGTH);
    var matcher = serializedString.match(this.BLOB_TYPE_PREFIX_REGEX);
    const blobType = matcher[1];
    serializedString = serializedString.substring(matcher[0].length);
    var buffer = this.stringToBuffer(serializedString);
    // var buffer = this.stringToBuffer(serializedString);
    // const res = await fetch(base64);
    // return await res.blob();
    return this.createBlob([buffer], { type: blobType });
  }

  async bufferToBlob(buffer: any): Promise<Blob> {
    return this.createBlob([buffer], { type: '[object Blob]' });
  }

  createBlob(parts, properties) {
    /* global BlobBuilder,MSBlobBuilder,MozBlobBuilder,WebKitBlobBuilder */
    parts = parts || [];
    properties = properties || {};
    try {
      return new Blob(parts, properties);
    } catch (e) {
      throw e;
    }
  }

  async exit() {
    const paramMap = await firstValueFrom(
      this.routeStateService.params.pipe(filter((p) => p != null))
    );

    const globalQueryParams = await firstValueFrom(
      this.routeStateService.queryParams.pipe(filter((p) => p != null))
    );

    const currentAction = paramMap.get('action');
    const expenseAnnotationId = parseInt(globalQueryParams['expenseAnnotationId'], 10);
    const expenseModelId = parseInt(globalQueryParams['expenseModelId'], 10);
    this.resetSteps();
    this.resetViewModel();
    if (expenseAnnotationId > 0) {
      const expenseAnnotationIdentity = new ExpenseAnnotationIdentity();
      expenseAnnotationIdentity.id = expenseAnnotationId;
      const rootModelFullName = EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_NAME;
      if (currentAction === 'add' && expenseModelId > 0) {
        const queryParams = {
          ...globalQueryParams,
          expenseAnnotationId,
          expenseModelId,
        }
        this.router.navigate([`${RECEIPT_LIST_FULL_PATH}`], { queryParams })
      } else {
        this.orchestratorViewModel.startLongOpClient<ExpenseAnnotationIdentity>(rootModelFullName, expenseAnnotationIdentity, null, true, false);
      }
    } else {
      await this.router.navigate([`${RECEIPT_LIST_FULL_PATH}`], { queryParams: globalQueryParams })
    }
  }

  async nextStep(skipRouting: boolean = false) {

    if (this.buttonLoading.value.loading === false) {

      // Prima di continuare verifico se lo step è valido
      const viewModel = this.orchestratorViewModel.rootViewModel.params[this.internalMachineState.state.property] as StepViewModelAwareInterface;
      await viewModel.validateStep();
      const isValid = await firstValueFrom(viewModel.isValid)

      if (!isValid) {
        this.toastMessageService.showToast({
          message: MessageResourceManager.Current.getMessage('StepNotValid'),
          title: MessageResourceManager.Current.getMessage('std_Warning'),
          type: ToastMessageType.warn
        });
        this.telemetryService.trackEvent({
          name: 'WEA_ReceiptLongOp_NextStepButNotValid',
          properties: {
            currentUrl: this.router.url
          }
        });
        return;
      }

      if (this.isLastStep()) {

        const currentUserId = await this.authService.getCurrentUserId();
        const expenseUserId = this.orchestratorViewModel.rootViewModel?.params?.userRef?.id?.value;
        const isReadOnly = currentUserId !== expenseUserId || (
          this.orchestratorViewModel.rootViewModel.params.expenseState.value !== ExpenseState.WebEditing &&
          this.orchestratorViewModel.rootViewModel.params.expenseState.value !== ExpenseState.IntegrateDocumentation
        )

        // se non sono in ReadOnly devo uscire all'ultimo step
        if (isReadOnly) {
          this.telemetryService.trackEvent({
            name: 'WEA_ReceiptLongOp_LastStepAndExit',
          });
          this.exit();
          return;
        }

        const paramMap = await firstValueFrom(
          this.routeStateService.params.pipe(filter((p) => p != null))
        );
        const currentId = parseInt(paramMap.get('id'), 10);
        this.telemetryService.trackEvent({
          name: 'WEA_ReceiptLongOp_LastStepAndSubmit',
        });
        this.onSubmit(currentId);
        return;
      }

      const result = await this.internalMachineState.next();

      if (result) {
        const paramMap = await firstValueFrom(
          this.routeStateService.params.pipe(filter((p) => p != null))
        );

        const queryParams = await firstValueFrom(
          this.routeStateService.queryParams.pipe(filter((p) => p != null))
        );
        const currentId = paramMap.get('id');
        const currentAction = paramMap.get('action');
        const nextRoute = `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${this.internalMachineState.state.slug}` + (currentId?.length > 0 ? ('/' + currentId) : '');
        this.telemetryService.trackEvent({
          name: 'WEA_ReceiptLongOp_NextStep',
          properties: {
            nextRoute
          }
        });
        await this.router.navigate([nextRoute], { queryParams });
      } else {
        this.toastMessageService.showToast({
          message: MessageResourceManager.Current.getMessage('NoOtherStepAvailable'),
          title: MessageResourceManager.Current.getMessage('std_Warning'),
          type: ToastMessageType.warn
        });
      }
    }
  }

  async previousStep() {
    if ((await firstValueFrom(this.buttonLoading))?.loading === false) {

      const paramMap = await firstValueFrom(
        this.routeStateService.params.pipe(filter((p) => p != null))
      );

      const queryParams = await firstValueFrom(
        this.routeStateService.queryParams.pipe(filter((p) => p != null))
      );
      const result = await this.internalMachineState.previous();

      // se posso andare indietro
      if (result) {

        const currentId = paramMap.get('id');
        const currentAction = paramMap.get('action');

        const previousRoute = `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${this.internalMachineState.state.slug}` + (currentId?.length > 0 ? ('/' + currentId) : '');
        this.telemetryService.trackEvent({
          name: 'WEA_ReceiptLongOp_PreviousStep',
          properties: {
            previousRoute
          }
        });
        await this.router.navigate(
          [previousRoute],
          { queryParams }
        )
      } else {
        this.telemetryService.trackEvent({
          name: 'WEA_ReceiptLongOp_PreviousStepAndExit',
        });
        this.exit();
      }
    }
  }

  async restart() {
    // this.updateCurrentStep(0);
    // this.workflow.forEach(element => {
    //   // element.valid = false;
    //   element.touched = false;
    //   element.submitted = false;
    // });
    // await this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}`]);
  }

  parseFromReceiptItem(receipt: ReceiptParams): ReceiptLongOp {
    const model = new ReceiptLongOp();
    model.id = receipt.id;
    model.params = receipt;
    return model;
  }

  populateViewModelsSteps() {
    for (const [key, value] of Object.entries(this.workflowNew)) {
      value.viewModel = this.orchestratorViewModel.rootViewModel.params[value.property]
    }

    // this.currentMa
  }

  getStepFromRoute(route: string) {
    // let step = null;
    // for (let i = 0; i < this.workflow.length; i++) {
    //   if (route.indexOf(`${RECEIPT_LONG_OP_FULL_PATH}/${this.workflow[i].slug}`) > -1) {
    //     step = this.workflow[i];
    //     break;
    //   }
    // }
    // return step;
  }

  getStepFromStepName(stepName: string) {
    // let step = null;
    // for (let i = 0; i < this.workflow.length; i++) {
    //   if (this.workflow[i].slug === stepName) {
    //     step = this.workflow[i];
    //     break;
    //   }
    // }
    // return step;
  }

  isLastStep(): boolean {
    return this.internalMachineState.state.lastStep === true;
  }

  isFirstStep(): boolean {
    return this.internalMachineState.state.firstStep === true;
  }

  resetViewModel() {
    this.initialized = false;
    this.internalMachineState = null;
  }

  resetSteps() {

    for (const [key, value] of Object.entries(this.workflowNew)) {

      if (value.viewModel && value.viewModel.destroySubscribers$) {
        value.viewModel.destroySubscribers$.next();
      }
    }
  }

  async recursiveFoundInvalidStates(
    stateMachine: StateMachine<ReceiptParamsViewModel, StepStateInterface>,
    invalidStates: string[]
  ) {
    const canGoBack = await stateMachine.previous();
    if (canGoBack) {
      const viewModel = this.orchestratorViewModel.rootViewModel.params[stateMachine.state.property] as StepViewModelAwareInterface;
      await viewModel.validateStep();
      const isValid = await firstValueFrom(viewModel.isValid)
      if (!isValid) {
        invalidStates.unshift(stateMachine.state.slug)
      }
      await this.recursiveFoundInvalidStates(stateMachine, invalidStates);
    }
  }

  async getFirstInvalidStep(step: string): Promise<string> {
    // If all the previous steps are validated, return blank
    // Otherwise, return the first invalid step
    // let found = false;
    // let valid = true;
    // let redirectToStep = '';


    const cleanStepValue = step.split('/').shift()?.toLowerCase();

    if (this.workflowNew[cleanStepValue] == null) {
      return RECEIPT_STEPS.expenseClassification;
    }
    const stateMachine = new StateMachine<ReceiptParamsViewModel, StepStateInterface>(
      this.workflowNew[cleanStepValue],
      this.orchestratorViewModel.rootViewModel.params
    );

    const viewModel = this.orchestratorViewModel.rootViewModel.params[stateMachine.state.property] as StepViewModelAwareInterface;
    await viewModel.validateStep();
    const isValid = await firstValueFrom(viewModel.isValid)

    const invalidStates = [];
    if (!isValid) {
      // Visto che lo step corrente non è valido lo aggiungo alla lista degli ste non validi
      invalidStates.push(stateMachine.state.slug)
    }

    await this.recursiveFoundInvalidStates(stateMachine, invalidStates)

    // Se non sono stati trovati step non validi
    if (invalidStates.length === 0) {
      return '' // non deve fare niente
    }

    // Se il primo step non valido trovato corrisponde con la rotta corrente
    if (cleanStepValue === invalidStates[0]) {
      return ''// non deve fare niente
    } else {
      return invalidStates[0]; // ritorna il primo step non valido
    }
  }

  getCurrentState() {
    return this.internalMachineState.data[this.internalMachineState.state.property];
  }

  private internalMachineState: StateMachine<ReceiptParamsViewModel, StepStateInterface>;

  updateCurrentStep(newStep?: number, currentRouteUrl?: string) {
    currentRouteUrl = currentRouteUrl || this.location.path();
    // if (newStep) {
    //   if (this.internalCurrentStep !== newStep) {
    //     this.internalCurrentStep = newStep;
    //     this.currentStepChanged.next(this.currentStep);
    //   }
    // } else {

    for (const [key, value] of Object.entries(this.workflowNew)) {

      if (RECEIPT_VALID_ACTIONS.map((action) =>
        currentRouteUrl.toLowerCase().startsWith(`${RECEIPT_LONG_OP_FULL_PATH}/${action}/${key}`.toLowerCase())
      ).some((found) => found == true)) {

        if (!this.internalMachineState) {
          this.internalMachineState = new StateMachine<ReceiptParamsViewModel, StepStateInterface>(
            this.workflowNew[key],
            this.orchestratorViewModel.rootViewModel.params
          );
        } else {
          // se lo slug non corrisponse con la key, vuol dire che è necessario riallineare la machine
          if (this.internalMachineState.state.slug !== key) {
            this.internalMachineState = new StateMachine<ReceiptParamsViewModel, StepStateInterface>(
              this.workflowNew[key],
              this.orchestratorViewModel.rootViewModel.params
            );
          }
        }
        this.currentStepChanged.next();
        break;
      }

      // for (let i = 0; i < this.workflow.length; i++) {
      //   if (RECEIPT_VALID_ACTIONS.map((action) =>
      //     currentRouteUrl.toLowerCase().startsWith(`${RECEIPT_LONG_OP_FULL_PATH}/${action}/${this.workflow[i].slug}`.toLowerCase())
      //   ).some((found) => found == true)) {
      //     if (this.internalCurrentStep !== i + 1) {
      //       this.internalCurrentStep = i + 1;
      //       this.currentStepChanged.next(this.currentStep);
      //       break;
      //     }
      //   }
      // }
    }
  }

  async parseFromOfflineReceipt(offlineReceipt: OfflineReceipt): Promise<ReceiptLongOp> {
    const model = new ReceiptLongOp();
    model.id = offlineReceipt.id;
    model.params = new ReceiptParams();
    model.params.id = offlineReceipt.id;
    model.params.userId = offlineReceipt.userId;

    // Se l'utente corrente corrisponde con quello della spesa
    const currentUserId = await this.authService.getCurrentUserId();

    // Recupero le informazioni dal token così evito di fare la chiamata
    if (model.params.userId == currentUserId) {
      const accessToken = await this.authService.getAccessToken()
      const parsedAccessToken = this.authService.decodeToken(accessToken);
      model.params.userRef = new UserOfTenantExtended();
      model.params.userRef.id = currentUserId;
      model.params.userRef.lastName = parsedAccessToken?.family_name;
      model.params.userRef.name = parsedAccessToken?.given_name;
    }

    model.params.expenseModelData = new ExpenseModelData();
    model.params.expenseModelData.expenseModelRef = offlineReceipt.expenseModelRef ? plainToClass<ExpenseModel, Object>(
      ExpenseModel, offlineReceipt.expenseModelRef as Object) : null;

    model.params.expenseSelection = new ExpenseSelection();
    model.params.expenseSelection.expenseClassification = offlineReceipt.expenseType;

    model.params.expenseData = new ExpenseData();

    if (offlineReceipt.expenseTypeRef['personalExpenseInstructions'] == null) {
      offlineReceipt.expenseTypeRef['personalExpenseInstructions'] = classToPlain(new PersonalCompilationInstructions(), { strategy: 'excludeAll' });
    }

    if (offlineReceipt.expenseTypeRef['availableExpenseInstructions'] == null) {
      offlineReceipt.expenseTypeRef['availableExpenseInstructions'] = classToPlain(new CompilationInstructions(), { strategy: 'excludeAll' });
    }

    model.params.expenseData.extendedExpenseTypeRef = offlineReceipt.expenseTypeRef ? plainToClass<ExtendedAvailableExpense, Object>(
      ExtendedAvailableExpense, offlineReceipt.expenseTypeRef as Object) : null;

    await this.orchestratorViewModel.fixExtendedAvailableExpense(model.params);

    model.params.expenseData.commissionRef = offlineReceipt.commissionRef ? plainToClass<Commission, Object>(
      Commission, offlineReceipt.commissionRef as Object) : null;

    model.params.expenseData.leadRef = offlineReceipt.leadRef ? plainToClass<Lead, Object>(
      Lead, offlineReceipt.leadRef as Object) : null;

    model.params.expenseData.customerRef = offlineReceipt.customerRef ? plainToClass<Customer, Object>(
      Customer, offlineReceipt.customerRef as Object) : null;

    model.params.expenseData.expenseTotalAmount = offlineReceipt.expenseTotalAmount;

    model.params.expenseData.files = new ExpenseFileCollection(
      null,
      null,
      offlineReceipt.expenseFiles.map((ei) => {
        return plainToClass<ExpenseFile, Object>(
          ExpenseFile, ei.expenseFile as Object)
      }
      )
    );

    model.params.expenseData.expenseDate = DateTimeOffset.formatFromString(offlineReceipt.expenseDate);
    model.params.expenseData.note = offlineReceipt.note;
    model.params.expenseData.description = offlineReceipt.description;

    model.params.foodData = new FoodData();
    model.params.foodData.peopleQuantity = offlineReceipt.peopleQuantity;
    model.params.foodData.guestQuantity = offlineReceipt.guestQuantity;

    model.params.accommodationData = new AccommodationData();
    model.params.accommodationData.peopleQuantity = offlineReceipt.peopleQuantity;
    model.params.accommodationData.nightQuantity = offlineReceipt.nightQuantity;

    model.params.transportData = new TransportData();
    model.params.transportData.peopleQuantity = offlineReceipt.peopleQuantity;

    model.params.mileageRefoundData = new MileageRefoundData();
    model.params.mileageRefoundData.distance = offlineReceipt.distance;
    model.params.mileageRefoundData.unitPrice = offlineReceipt.unitPrice;

    model.params.otherData = new OtherData();
    model.params.otherData.peopleQuantity = offlineReceipt.peopleQuantity;
    model.params.otherData.guestQuantity = offlineReceipt.guestQuantity;

    model.params.paymentData = new PaymentData();
    model.params.paymentData.extendedPaymentTypeRef = offlineReceipt.paymentTypeRef ? plainToClass<ExtendedAvailablePayment, Object>(
      ExtendedAvailablePayment, offlineReceipt.paymentTypeRef as Object) : null;

    await this.orchestratorViewModel.fixExtendedAvailablePayment(model.params);

    model.params.paymentData.reliableSupplier = offlineReceipt.reliableSupplier;
    model.params.paymentData.supplierRef = offlineReceipt.supplierRef ? plainToClass<Supplier, Object>(
      Supplier, offlineReceipt.supplierRef as Object) : null;

    model.params.paymentData.businessName = offlineReceipt.businessName;
    model.params.paymentData.vatNumber = offlineReceipt.vatNumber;


    return model;
  }
}

