import { CurrencyPipe, DatePipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthService, CodeValueMessageArg, FilledButtonComponent, LoaderComponent, MessageButton, MessageCodes, MessageResourceManager, MessageResult, ModalService, NTSTranslatePipe, plainToClass, RibbonButtonComponent, RoutingService, TextButtonComponent, ToastMessageService, ToastMessageType } from "@nts/std";
import { ExpenseClassificationMapper } from "src/app/shared/services/expense-classification-mapper";
import { UntilDestroy } from "@ngneat/until-destroy";
import { OfflineReceipt } from "src/app/shared/models/offline-receipt.interface";
import { ExpenseClassification } from "src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification";
import { EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_PATH, RECEIPT_LONG_OP_FULL_PATH } from "src/app/shared/shared.module";
import { ExpenseAnnotationIdentity } from "src/app/expense-annotation/domain-models/expense-annotation.identity";
import { ExpenseModel } from "src/app/expense-model/domain-models/expense-model";
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { ChangeDetectionStrategy } from "@angular/core";
import { LocalstorageHelper } from "@nts/std/src/lib/utility";
import { UIStarter } from "@nts/std";
import { TelemetryService } from "@nts/std/src/lib/telemetry";
import { SvgIconComponent } from '@ngneat/svg-icon';
import { SingleExpenseComponent } from "src/app/shared/components/single-expense/single-expense.component";
import { ReceiptForCompanyFilterPipe } from "../../pipes/receipt-for-company";
import { SelectedReceiptsFilterPipe } from "../../pipes/selected-receipts.pipe";
import { FullscreenPreviewComponent } from "src/app/shared/components/fullscreen-preview/fullscreen-preview.component";
import { ReceiptLongOpApiClient } from "src/app/receipt-long-op/api-clients/receipt-long-op.api-client";
import { firstValueFrom, take } from "rxjs";
import { Label } from "src/app/classification-labels/domain-models/label";

@UntilDestroy()
@Component({
  selector: 'app-receipt-dashboard',
  templateUrl: './receipt-dashboard.component.html',
  styleUrls: ['./receipt-dashboard.component.scss'],
  standalone: true,
  providers: [CurrencyPipe, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SvgIconComponent,
    NgIf,
    LoaderComponent,
    SingleExpenseComponent,
    NTSTranslatePipe,
    RibbonButtonComponent,
    NgFor,
    ReceiptForCompanyFilterPipe,
    NTSTranslatePipe,
    SelectedReceiptsFilterPipe,
    TextButtonComponent,
    FilledButtonComponent,
    FullscreenPreviewComponent
  ]
})
export class ReceiptDashboardComponent implements OnInit {

  private CROSS_SITE_TRACKING_KEY = 'crossSiteTracking';

  receipts: OfflineReceipt[] = [];
  expenseTypeEnum = ExpenseClassification;
  expenseTypeList = [];
  expenseTypeMapper = ExpenseClassificationMapper;
  expenseTypeCounter = {};
  defaultCurrency = 'EUR';
  currentImageModalSrc: SafeResourceUrl = null
  selectionMode = false;
  showActions = false;
  inIframe = false;
  showLoader = false;
  showCounters = false;
  expenseAnnotationId: number;
  expenseModelId: number;
  currencyIso: string = null;
  currentCompanyId: number = null;
  private queryParams: Params;

  newLabels = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private readonly cd: ChangeDetectorRef,
    private readonly telemetryService: TelemetryService,
    private apiClient: ReceiptLongOpApiClient,
  ) {
    this.activatedRoute.snapshot.queryParams['iframe'] === 'true';
  }

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (document.hidden) {
    } else {
      this.loadReceipts();
    }
  }

  checkSingleCount(filteredByIndex: OfflineReceipt[], expenseType: ExpenseClassification) {
    // const currencies: string[] = [...new Set<string>(filteredByIndex?.map(a => a.currency))];
    const currencies = ['EUR']
    let total = currencies.map((c) => {
      // const totalValue = filteredByIndex.filter((a) => a.currency === c).reduce((previous, current) => {
      const totalValue = filteredByIndex.reduce((previous, current) => {
        return previous + current.expenseTotalAmount;
      }, 0)
      const totalString = this.currencyPipe.transform(totalValue, c, 'symbol');
      return totalString;
    }).join(' - ')
    if (total?.length < 1) {
      total = this.currencyPipe.transform(0, this.defaultCurrency, 'symbol');
    }
    this.expenseTypeCounter[expenseType] = {
      qty: filteredByIndex?.length ?? 0,
      currencies,
      total
    }
  }

  async checkCounters() {

    let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

    if (!receipts || !Array.isArray(receipts)) {
      receipts = [];
    }

    this.checkSingleCount(receipts.filter((r) => r.expenseType === ExpenseClassification.Accommodation), ExpenseClassification.Accommodation);
    this.checkSingleCount(receipts.filter((r) => r.expenseType === ExpenseClassification.Food), ExpenseClassification.Food);
    this.checkSingleCount(receipts.filter((r) => r.expenseType === ExpenseClassification.MileageRefound), ExpenseClassification.MileageRefound);
    this.checkSingleCount(receipts.filter((r) => r.expenseType === ExpenseClassification.Other), ExpenseClassification.Other);
    this.checkSingleCount(receipts.filter((r) => r.expenseType === ExpenseClassification.Transport), ExpenseClassification.Transport);
  }

  async ngOnInit(): Promise<void> {

    this.authService.getTenantId().then(async (tenantId: number) => {
      const enterpriseData = await this.authService.getEnterpriseData(tenantId);
      this.currentCompanyId = enterpriseData.companyId;
      this.cd.detectChanges();
    })

    const title = MessageResourceManager.Current.getMessage('ReceiptDashBoard_Title');
    this.telemetryService.trackPageView({ name: title });

    const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();

    LocalstorageHelper.getStorageItem(`${this.CROSS_SITE_TRACKING_KEY}`, null, true, false).then(async (value: boolean) => {
      if (value !== true) {
        if (deviceInfo.os === 'iOS' && deviceInfo.browser === 'Safari') {
          this.modalService.showMessageAsync('Attenzione', `Per funzionare correttamente su questo dispositivo, è necessario disabilitare l'opzione "Blocca cross-site tracking" dalle Impostazioni->Safari->Privacy e sicurezza. Cliccare su Ok dopo averla disattivata.`, MessageButton.Ok)
        }
        await LocalstorageHelper.setStorageItem(`${this.CROSS_SITE_TRACKING_KEY}`, true, null, true, false);
        window.location.reload();
      }
    })

    await this.getClassificationLabels();

    this.showLoader = true;
    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;
    this.expenseAnnotationId = parseInt(this.activatedRoute.snapshot.queryParams['expenseAnnotationId'], 10);
    this.expenseModelId = parseInt(this.activatedRoute.snapshot.queryParams['expenseModelId'], 10);
    this.currencyIso = this.activatedRoute.snapshot.queryParams['isoCode'];


    this.showActions = this.expenseAnnotationId > 0;
    this.showCounters = !this.expenseAnnotationId || this.expenseAnnotationId == 0;
    this.expenseTypeList = Object.values(ExpenseClassification).filter((v) => !isNaN(Number(v)));
    this.selectionMode = this.expenseAnnotationId > 0;

    this.loadReceipts();

    this.activatedRoute.queryParams.subscribe(queryParams => this.queryParams = queryParams);

    setTimeout(() => {
      UIStarter.updateCurrentRoute(
        'WebExpenseAnnotation.ReceiptLongOpObjects.Models.ReceiptLongOp',
        null,
        null,
        null,
        `/manage/receiptlist/dashboard${window.location.search.toString()}`
      );
    }, 100)
  }

  async loadReceipts(): Promise<any> {
    return LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true).then(async (receipts: OfflineReceipt[]) => {

      if (!receipts || !Array.isArray(receipts)) {
        receipts = [];
      }

      this.receipts = receipts.filter((offlineReceipt) => {

        if (this.expenseModelId > 0) {
          const expenseModel: ExpenseModel = offlineReceipt.expenseModelRef ?
            plainToClass<ExpenseModel, Object>(ExpenseModel, offlineReceipt.expenseModelRef as Object) :
            null;

          if (expenseModel && this.expenseModelId === expenseModel.modelCode) {
            return true;
          }

          return false;
        }
        return true;
      })

      await this.checkCounters();

      this.showLoader = false;
      this.cd.markForCheck();
    })
  }

  newReceipt() {
    this.showLoader = true;
    this.cd.markForCheck();
    const queryParams = this.queryParams;
    this.telemetryService.trackEvent({ name: 'WEA_ReceiptList_AddReceipt' });
    this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/new`], { queryParams })
  }

  editReceipt(receipt: OfflineReceipt) {
    this.showLoader = true;
    this.cd.markForCheck();
    const queryParams = this.queryParams;
    this.telemetryService.trackEvent({ name: 'WEA_ReceiptList_EditReceipt' });
    this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/edit/${receipt.id}`], { queryParams })
  }

  async onReceiptSelectionChanged(isSelected: boolean, receipt: OfflineReceipt) {
    receipt.isSelected = isSelected;
    await LocalstorageHelper.setStorageItem('receipts', this.receipts, null, true, true, true);
  }

  getQtyByExpenseType(expenseType: ExpenseClassification) {
    return this.expenseTypeCounter[expenseType] ? this.expenseTypeCounter[expenseType]['qty'] : 0;
  }

  getTotalByExpenseType(expenseType: ExpenseClassification) {
    return this.expenseTypeCounter[expenseType] ? this.expenseTypeCounter[expenseType]['total'] : 0;
  }

  getSrcFromReceipt(receipt: OfflineReceipt) {
    return null;
    // return this.sanitizer.bypassSecurityTrustResourceUrl(receipt.expenseFiles[0].localDeviceData);
  }

  openFullImagePreview(receipt: OfflineReceipt) {
    // this.currentImageModalSrc = this.sanitizer.bypassSecurityTrustResourceUrl(receipt.expenseFiles[0].localDeviceData);
  }

  onPreviousStep() {
    const expenseAnnotationIdentity = new ExpenseAnnotationIdentity();
    expenseAnnotationIdentity.id = this.expenseAnnotationId;
    const serializedIdentity = RoutingService.encodeObject(expenseAnnotationIdentity);
    const queryParams = {
      ...this.queryParams,
      'history-back': true
    }
    this.router.navigate([`${EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_PATH}/${serializedIdentity}`], { queryParams })
  }

  onAddSelectedReceipts() {
    const queryParams = {
      ...this.queryParams,
      expenseAnnotationId: this.expenseAnnotationId,
      expenseModelId: this.expenseModelId,
    };
    this.showLoader = true;
    this.cd.markForCheck();
    this.telemetryService.trackEvent({ name: 'WEA_ReceiptList_AddReceipt' });
    this.router.navigate([`${RECEIPT_LONG_OP_FULL_PATH}/add/selected-receipts`], { queryParams });
  }

  async deleteReceipt(receipt: OfflineReceipt) {

    const warningMessage = MessageResourceManager.Current.getMessage(MessageCodes.Warning);
    const amount = this.currencyPipe.transform(receipt.expenseTotalAmount, 'EUR', 'symbol');
    const category = MessageResourceManager.Current.getMessage('ExpenseClassification_' + ExpenseClassification[receipt.expenseType]);
    const creationDate = this.datePipe.transform(receipt.expenseDate, 'd/MM/yyyy')

    const args: Array<CodeValueMessageArg> = [
      new CodeValueMessageArg('Category', category),
      new CodeValueMessageArg('Amount', amount),
      new CodeValueMessageArg('CreationDate', creationDate)
    ];

    const result = await this.modalService.showMessageAsync(warningMessage, MessageResourceManager.Current.getMessageWithArgs('DeleteReceiptConfirmMessage', args), MessageButton.YesNo);

    if (result == MessageResult.Yes) {

      let receipts: OfflineReceipt[] = await LocalstorageHelper.getStorageItem('receipts', undefined, true, true, true) as OfflineReceipt[];

      if (!receipts || !Array.isArray(receipts)) {
        receipts = [];
      }

      if (receipts?.length > 0) {
        const receiptIndex = receipts.findIndex(r => r.id === receipt.id);
        if (receiptIndex > -1) {
          // Rimuovo la receipt locale appena aggiunta
          receipts.splice(receiptIndex, 1);
          await LocalstorageHelper.setStorageItem('receipts', receipts, null, true, true, true);
          this.receipts = receipts;
          await this.checkCounters();
        }
      }
    }
    this.telemetryService.trackEvent({ name: 'WEA_ReceiptList_DeleteReceipt' });
    this.cd.detectChanges();
  }

  async getClassificationLabels(): Promise<void> {

    const res = await firstValueFrom(this.apiClient.getClassificationLabelsAsync().pipe(
      take(1),
    ));

    if (res.operationSuccedeed) {
      let newClass: Label[] = res.result;

      if (newClass && newClass != null && newClass.length != 0) {
        newClass.forEach(e => {
          this.newLabels[e.classification] = e.labelDefinition;
        })
      }
    }
  }
}
